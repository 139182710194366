import { handleAfterLoginComplete, processAuthResponse, useAuth } from '@components/Auth';
import IconTelegram from '@components/Icons/iconTelegram';
import IconZalo from '@components/Icons/IconZalo';
import { useModal } from '@components/ModalHook';
import { MODAL_ID as POPUP_USER_SENT_INFORMATION } from '@components/popup/PopupUserSentInformation';
import VnrLink from '@components/VnrLink';
import { createAnonymousUserData } from '@services/anonymousUserData.service';
import { updateAvatar } from '@services/user.service';
import { updateUserProfile } from '@services/user.service';
import { triggerEventUserFillInformation } from '@utils/gtmUtil';
import { localStorageUtil } from '@utils/storageUtil';
import { validateEmail, validatePhoneNumber } from '@utils/validationRules';
import { Button, Checkbox, Col, Form, Input, Row, Select } from 'antd';
import dayjs from 'dayjs';
import Router, { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { marketOptions, servicesOptions } from 'src/constants';
import { PHONE_CODE } from 'src/constants/phoneCode';
import provinces from 'src/constants/provinces';
import useTranslate from 'src/hook/useTranslate';

import ArrowFlip from './ArrowFlip';
import { serviceNameMapper } from './ProductRegisterForm';
import styles from './Profile.module.scss';
import Timer from './Timer';
import UploadAvatar from './UploadAvatar';

const avatar_default = '/images/avatar.png';
// const messengerIcon = '/images/icon-mes-facebook.png';
export const POPUP_ID = 'PROFILE_INFORMATION';
export const INIT_COUNTRY_CODE = {
  vi: '+84',
  en: '+44',
  cn: '+86',
};
const ProfileInformation = ({ isOnProfilePage = true, onFinish }) => {
  const translate = useTranslate();
  const [dropDownOpen, setDropDownOpen] = useState(false);
  const [, showModal] = useModal(POPUP_USER_SENT_INFORMATION);
  const [countDownDay, setCountDownDay] = useState();
  const { user } = useAuth();
  const [form] = Form.useForm();
  const [imageUrl, setImageUrl] = useState();
  const [isHide, setIsHide] = useState(false);
  const dispatchApp = useDispatch().app;
  const positionLogin = useSelector((state) => state.app.auth.positionLogin);
  const afterLoginSuccess = useSelector((state) => state.app.auth.afterLoginSuccess);
  const userLocalInformationString = localStorageUtil.getItem('user_local_information');
  const userLocalInformation =
    !!userLocalInformationString && userLocalInformationString !== ''
      ? JSON.parse(userLocalInformationString)
      : null;
  const isCollaborator = user.registrationRole && user.registrationRole !== '';
  const { locale } = useRouter();

  const handleUpdateAvatar = async (imageUrl) => {
    if (!imageUrl) {
      return;
    }
    if (imageUrl === user?.imageUrl) return;

    const res = await updateAvatar({
      userId: user.id,
      userAvatar: imageUrl,
    });
    if (res?.data) {
      console.log('Cập nhật avatar thành công.');
    }
    return;
  };

  const handleSaveInfo = (e) => {
    e.preventDefault();
    form
      .validateFields()
      .then(async (values) => {
        const { newUserEmail, userName, phone, phoneCode, province, services, market } = values;
        const serviceString = services.join(', ');
        const url = new URL(document.URL);
        const source = url.searchParams?.get('utm_source') || '';
        const campaign = url.searchParams?.get('utm_campaign') || '';
        const content = url.searchParams?.get('utm_content') || '';
        const dataRequest = {
          ...values,
          newUserEmail,
          userName,
          phone,
          phoneCode,
          province,
          services: JSON.stringify(services),
          market: JSON.stringify(market),
          source,
          campaign,
          content,
        };
        try {
          if (isOnProfilePage) {
            handleUpdateAvatar(imageUrl);
          }

          //check login, lưu data mới + cache / update
          if (!user?.id) {
            const res = await createAnonymousUserData(dataRequest);
            localStorageUtil.setItem('user_local_information', JSON.stringify(dataRequest));
            if (res?.data) {
              showModal({
                text: serviceString
                  .replace(
                    'Khóa Video 1 - VSA & Volume Master (200$)',
                    'Truy cập FREE - Khóa "Trading cho Người mới"'
                  )
                  .replace(
                    'Khóa Video 2 - SMC tinh gọn (200$)',
                    'Voucher 30% Freeship - Flashcard "Trading chuyên sâu"'
                  )
                  .replace(
                    'Khóa Video 3 - Supply & Demand 2.0 (200$)',
                    'Voucher 40% - Khóa học video "Trading chuyên sâu"'
                  ),
              });
            }
          } else {
            const res = await updateUserProfile(dataRequest);
            if (res.data) {
              showModal({
                text: serviceString
                  .replace('SERVICE_01', 'Nhận ngay VnRebates Airdrop 20$-50$ tiền mặt')
                  .replace(
                    'Khóa Video 1 - VSA & Volume Master (200$)',
                    'Truy cập FREE - Khóa "Trading cho Người mới"'
                  )
                  .replace(
                    'Khóa Video 2 - SMC tinh gọn (200$)',
                    'Voucher 30% Freeship - Flashcard "Trading chuyên sâu"'
                  )
                  .replace(
                    'Khóa Video 3 - Supply & Demand 2.0 (200$)',
                    'Voucher 40% - Khóa học video "Trading chuyên sâu"'
                  ),
              });
              processAuthResponse(res.data?.body);
              triggerEventUserFillInformation(dataRequest);
              handleAfterLoginComplete(dispatchApp, res.data?.body, positionLogin, {
                afterLoginSuccess,
              });
            }
          }
          if (onFinish) {
            onFinish();
          }

          if (!isOnProfilePage) {
            setIsHide(true);
          } else {
            Router.push('/dashboard');
          }
        } catch (e) {
          dispatchApp.setLoginUser();
          console.error(e.response.data.message);
        }
      })
      .catch(() => {});
  };

  const updateUserInfoFromCache = async (dataRequest) => {
    const res = await updateUserProfile(dataRequest);
    if (res.data) {
      console.log('Update thông tin thành công!');
      localStorageUtil.setItem('user_local_information', '');
      if (!isOnProfilePage) {
        setIsHide(true);
      }
    }
  };

  // const InputAddonBefore = ({ text }) => {
  //   return <div className={styles.inputAddonBefore}>{text}</div>;
  // };

  useEffect(() => {
    if (
      !isOnProfilePage &&
      user?.market &&
      user.market !== '' &&
      user?.services &&
      user.services !== ''
    ) {
      setIsHide(true);
    } else {
      setIsHide(false);
    }

    //trường hợp có data cache + user chưa nhập thông tin services, market + email giống: update thông tin chưa nhập
    if (
      user?.id &&
      userLocalInformation &&
      userLocalInformation !== '' &&
      (!user?.market || user.market === '') &&
      (!user?.services || user.services === '') &&
      userLocalInformation.newUserEmail === user?.email
    ) {
      if (user?.name && user.name !== '') {
        userLocalInformation.userName = user.name;
      }
      if (user?.phone && user.phone !== '') {
        userLocalInformation.phone = user.phone;
      }
      if (user?.province && user.province !== '') {
        userLocalInformation.province = user.province;
      }
      if (user?.market && user.market !== '') {
        userLocalInformation.market = user.market;
      }
      if (user?.services && user.services !== '') {
        userLocalInformation.services = user.services;
      }
      updateUserInfoFromCache(userLocalInformation);
    }

    //init data form
    let services = [];
    let market = [];
    if (user?.services && user?.services !== '') {
      services = JSON.parse(user.services);
    }
    if (user?.market && user?.market !== '') {
      market = JSON.parse(user.market);
    }
    const phoneCode = user?.phoneCode || INIT_COUNTRY_CODE[locale];
    form.setFieldsValue({
      ...user,
      newUserEmail: user.email,
      userName: user.name,
      province: user.province,
      phone: user.phone,
      services,
      market,
      phoneCode,
    });

    const dateCreated = dayjs(user?.createdDate);
    const dayToCountDown = dateCreated.add(1, 'day');

    if (dayjs().isBefore(dayToCountDown)) {
      setCountDownDay(dayToCountDown);
    } else {
      setCountDownDay(false);
    }
  }, [user]);

  if (isHide) return <div></div>;
  return (
    <Row className={isOnProfilePage ? null : styles.profileInfoRow}>
      {!isOnProfilePage && <div className={styles.guildText}>{translate.profile.guildText}</div>}
      <Col sm={{ span: 24 }} md={{ span: 24 }} className={styles['vnrb-comp-profile-update-info']}>
        {isOnProfilePage && (
          <>
            {!isCollaborator && (
              <div className={styles['profile-title']}>
                <div className={styles.background}>
                  <div className={styles.headerText}>{translate.profile.title}</div>
                  {countDownDay && (
                    <div className={styles.flipCountdown}>
                      <Timer deadline={countDownDay} />
                    </div>
                  )}
                </div>
                <div>{translate.profile.title}</div>
                {countDownDay && (
                  <div className={styles.flipCountdown}>
                    <Timer deadline={countDownDay} />
                  </div>
                )}
              </div>
            )}

            <UploadAvatar
              id={user.id}
              currentAvatar={imageUrl || user.imageUrl || avatar_default}
              setImageUrl={setImageUrl}
            />
            <div className={styles['profile-email']}>{user.name ? user.name : user.email}</div>
          </>
        )}
        <Form form={form} name="user-info" className={styles.formWrapper}>
          <Row className={styles.section} gutter={[6, 6]}>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
              <Form.Item>
                {/* <InputAddonBefore text="Họ và tên" /> */}
                <Form.Item
                  name="userName"
                  rules={[
                    {
                      required: true,
                      message: 'Nhập họ tên',
                    },
                  ]}
                >
                  <Input placeholder={translate.profile.name} className={styles.inputForm} />
                </Form.Item>
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
              <Form.Item>
                {/* <InputAddonBefore text="Email" /> */}
                <Form.Item
                  name="newUserEmail"
                  rules={[
                    () => ({
                      validator(_, value) {
                        if (validateEmail(value)) {
                          return Promise.resolve();
                        }
                        return Promise.reject(new Error('Email không đúng!'));
                      },
                    }),
                  ]}
                >
                  <Input placeholder={translate.profile.email} className={styles.inputForm} />
                </Form.Item>
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
              <div className={styles.selectRebatePerUnit}>
                <div className={styles.selectUnit}>
                  <Form.Item name="phoneCode">
                    <Select
                      onDropdownVisibleChange={(open) => setDropDownOpen(open)}
                      suffixIcon={
                        <div className={styles.arrowFlipWrapper}>
                          <ArrowFlip open={dropDownOpen} />
                        </div>
                      }
                      popupClassName={styles.dropDownMenu}
                    >
                      {PHONE_CODE.map((item, index) => (
                        <Select.Option value={item.code} key={index}>
                          {item.code} <span className={styles.countryName}>{item.name}</span>
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>
                <div className={styles.phoneNumberForm}>
                  <Form.Item
                    name="phone"
                    rules={[
                      () => ({
                        validator(_, value) {
                          if (!value)
                            return Promise.reject(new Error('Vui lòng nhập số điện thoại!'));

                          if (validatePhoneNumber(value)) {
                            return Promise.resolve();
                          }
                          return Promise.reject(new Error('Số điện thoại không hợp lệ!'));
                        },
                      }),
                    ]}
                  >
                    <Input className={styles.inputFormUnitValue} />
                  </Form.Item>
                </div>
              </div>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
              <Form.Item>
                {/* <InputAddonBefore text="Tỉnh" /> */}
                <Form.Item
                  name="province"
                  rules={[
                    {
                      required: true,
                      message: 'Chọn tỉnh',
                    },
                  ]}
                >
                  <Select
                    placeholder={translate.profile.province}
                    defaultActiveFirstOption
                    className={styles.inputForm}
                  >
                    {provinces.map((province) => (
                      <Select.Option key={province}>{province}</Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Form.Item>
            </Col>

            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
              <div>
                <b>{translate.profile.servicesQuestion}</b>
              </div>
              <Form.Item
                name="services"
                rules={[
                  {
                    required: true,
                    message: 'Vui lòng chọn dịch vụ bạn đang quan tâm!',
                  },
                ]}
              >
                <Checkbox.Group>
                  {servicesOptions.map((item) => {
                    return (
                      <div key={item.code}>
                        <Checkbox value={item.code}></Checkbox>
                        {serviceNameMapper[item.code]}
                      </div>
                    );
                  })}
                </Checkbox.Group>
              </Form.Item>
            </Col>

            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
              <div>
                <b>{translate.profile.martkets}</b>
              </div>
              <Form.Item
                name="market"
                rules={[
                  {
                    required: true,
                    message: 'Vui lòng chọn thị trường bạn đang quan tâm!',
                  },
                ]}
              >
                <Checkbox.Group>
                  {marketOptions.map((item) => {
                    return (
                      <>
                        <Checkbox value={item.code}></Checkbox>
                        <VnrLink to={item.link} className={styles.optionName}>
                          {item.name[locale]}
                        </VnrLink>
                        <br />
                      </>
                    );
                  })}
                </Checkbox.Group>
              </Form.Item>
            </Col>
          </Row>
          <Col>
            <Button onClick={handleSaveInfo} className={styles.buttonLogin} type="primary">
              <span className={styles.textLogin}>{translate.profile.buttonText}</span>
            </Button>

            <div className={styles.supportInfo}>
              Liên hệ support:
              <VnrLink to="https://zalo.me/0942520381">
                <IconZalo />
              </VnrLink>
              <VnrLink to="https://t.me/vnrebatessupport">
                <IconTelegram />
              </VnrLink>
            </div>
          </Col>
        </Form>
      </Col>
    </Row>
  );
};

export default ProfileInformation;
